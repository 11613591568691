.footer {
   width: 100%;
   background-color: #3f51b5;
   color: darken($color: white, $amount: 20);
   line-height: 70px;
   text-align: center;
   margin-top: 70px;
   a {
      color: white;
      height: 30px;
      text-decoration: none;
      text-shadow: 0px 0px 3px;
   }
}
